import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

export default function Gallery({ data }) {
  const images = data.galleryImages.edges.map(
    edge => edge.node.childImageSharp
  );

  return (
    <Layout title="Gallery">
      <h2 className="major">Gallery</h2>
      <h3 className="minor">Past Projects</h3>

      <div id="photos">
        {images.map(image => (
          <a key={image.original.src} href={image.original.src}>
            <img srcSet={image.thumbnail.srcSet} />
          </a>
        ))}
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    galleryImages: allFile(
      filter: { sourceInstanceName: { eq: "gallery-images" } }
      sort: { order: DESC, fields: publicURL }
    ) {
      edges {
        node {
          childImageSharp {
            original {
              src
            }
            thumbnail: fixed(width: 176) {
              srcSet
            }
          }
        }
      }
    }
  }
`;
